import React, { PropsWithChildren, ReactElement } from "react";
import useWindowWidth, { heroDesktopMinWidth } from "../../hooks/useWindowWidth";
import { graphql } from "gatsby"
import styles from "./Terms.module.scss"
import Helmet from "../../components/Helmet";
import Hero from "../../components/shared/HeroStaticPages";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";

interface ITermTexts {
    type: string, text: string, spans: any[]
}

const TermsOfService = (props: PropsWithChildren<any>): ReactElement => {
    const data = !props.fromForm ?
        JSON.parse(props.data.allPrismicTermsOfService.edges[0].node.dataString) :
        props.data;

    const isDesktop = useWindowWidth(heroDesktopMinWidth)
    const background = isDesktop ? data.hero_background?.url : data.mobile_background?.url || data.hero_background?.url

    return (
        <>
            {
                !props.fromForm && <Helmet
                    title={getSeoTitle(data, "Terms of service")}
                    description={getSeoDescription(data, "Terms of service page")}
                    keywords={getSeoKeywords(data, "")} 
                />
            }

            {background && !props.fromForm && <Hero img={background} />}

            <main className={`mainBox ${styles.mainWrapper} ${props.fromForm ? styles.managePopupClasses : ""}`}>
                <div>
                    <section className={styles.mainInformation}>
                        {data.terms_title && <h2 className={styles.mainTitle}>{data.terms_title[0].text}</h2>}
                        {data.terms_introduction &&
                            data.terms_introduction.map((introduction: ITermTexts, i: number) => (
                                <p className={styles.mainDescription} key={i}>{introduction.text}</p>
                            ))}
                    </section>
                    <section>
                        {data.terms_section && data.terms_section.map((information: { term_title: Array<ITermTexts>, term_information: Array<ITermTexts> }, i: number) => {
                            return <div key={i}>
                                <h3 className={styles.termSectionTitle}>{information.term_title[0]?.text}</h3>

                                {information.term_information.map((term: ITermTexts, i: number) => (
                                    <p className={styles.termSectionDescription} key={i}>{term?.text}</p>
                                ))}
                            </div>
                        })}
                    </section>
                </div>
            </main>
        </>
    );
};

export const query = graphql`
    query {
        allPrismicTermsOfService {
            edges {
                node {
                    dataString
                }
            }
        }
    }
`;

export default TermsOfService;
